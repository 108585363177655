@import "../template/mixin_templates";
@import "../variables";
@import "../../foundation/functions";

/* Général */
body, #nav-static-principal > ul > li > a, #fiche-produit > h2 + form > div:first-child{
    color: $body_texte_color;
}
body{
    background-color: $body_background_color;    
}
.content_p, #nav-static-principal > ul > li > a{
    background-color: $content_background_color;
}
a, a:visited{
    color: $link_color;
}
a:hover, a:active, a:focus{
    color: $link_hover_color;
}

.button.button-secondary:not(.zoom) {
    background: #444;
    color: #fff;
    border-color: #444;

    &:hover {
        background: #fff;
        color: #444;
        border-color: #444;
    }
}

a.button, .button, button[type=submit],.produits a.button{
    background-color: $button_background_color;
    border-color: $button_border_color;
    color: $button_color;
}
a.button:hover:not(.zoom), .button:hover:not(.zoom), button[type=submit]:hover{
    background-color: $button_hover_background_color;
    border-color: $button_hover_border_color;
    color: $button_hover_color;
}
h2, .h2, .single-article .titre-article h1, .single-article .titre-article .h1{
    color: $h2_color;
    background-color: $h2_background_color;
    a, a:hover{
        color: inherit !important;
    }
}
.formulaires_perso input[type=submit]{
    background-color: $button_background_color !important;
}


/* Header */
header{
    background-color: $header_background_color;
}
.slogan{
    color: $slogan_color;
}
.navigation_p{
    background-color: $menu_background_color;
}
#nav-principal{
    .navigation_link_p{
        color: $nav_color;
    }
    .navigation_button_p{
        background-color: $nav_background_color;
        &.actif, &:hover, &.sousmenu_ouvert{
            background-color: $nav_actif_background_color;
            > .navigation_link_p{
                color: $nav_actif_color;
            }
        }
    }
    .sous_navigation_link_p{
        color: $nav_color;
    }
    .sous_navigation_button_p{
        background-color: $nav_background_color;
        &.actif, &:hover, &.sousmenu_ouvert{
            background-color: $nav_actif_background_color;
            > .sous_navigation_link_p{
                color: $nav_actif_color;
            }
        }
    }
}
.sidebar ul li li, .side-tabs.top > a{
    background-color: $nav_background_color;
    color: $nav_color;
}
.navigation_button_p a, .side-tabs.top > a{
    color: $nav_color;
}
.sidebar ul li li:hover, .side-tabs.top > a:hover{
    background-color: $nav_actif_background_color;
}
.navigation_link_hover, .sous_navigation_link_hover, aside.sidebar li li:hover a, aside.sidebar li li.actif a, .side-tabs.top > a:hover{
    color: $nav_actif_color;
}
.menu_burger{
    color: $menu_burger_color;
}
.menu_burger:hover{
    color: $menu_burger_hover_color;
    background-color: $menu_burger_hover_background_color;
}

/* Content */
#nav-static-principal > a{
    color: $sidebar_rubrique_color;
    border-color: $sidebar_rubrique_border_color;
}
nav.secondaire .sous_navigation_link_p.actif{
    color: $fil_darianne_actif_color;
}
.formulaires_perso, .form-newsletter{
    background-color: $form_perso_background_color;   
}


/* accueil */
.produits-accueil .mask{
    //background-color: $prod_accueil_background_color;
}

.slide-text p, .slide-text a{
    background-color: $slider_titre_background_color;
}


/* boutique */
.produits, #fiche-produit, #fiche-produit nav.secondaire a, #fiche-produit .container-comment h4, .content #fiche-produit .top_fiche .wrap-details h4, #fiche-produit .container-comment .h4, .content #fiche-produit .top_fiche .wrap-details .h4, .info-photo, .produits a{
    color: $produit_color;
}
.produit_etiquette{
    background-color: $etiquette_background_color;    
}
.produits .remise{
    background-color: $etiquette_background_color;
}
.produit_etiquette h3,
.produit_etiquette .h3{
    color: $etiquette_color;
}
.remise{
    color: $remise_color;
}
.produits .zoom, .photo .zoom{
    background-color: $zoom_background_color;
}
.prixprod{
    color: $prix_color;
}
.oldprix, #fiche-produit .wrap-description .prix span.oldprix{
    color: $old_prix_color;
}

.content #fiche-produit .remise-produit{
    background-color: $ficheprod_remise_background_color;
}


/* galerie */
.info-photo a{
    color: $link_hover_color;
}


/* livre d'or */
.content .message_lo .sous-titre{
    color: $msglo_h2_color;
    background-color: $msglo_h2_background_color !important;  
}
.message_lo .note{
    background-color: $msglo_note_background_color;
    color: $msglo_note_color;
}


/* contact */
form input:focus, form textarea:focus, form select:focus{
    box-shadow: $form_focus_shadow;
    border-color: $form_focus_bordercolor; 
}



/* Footer */
.footer_p{
    color: $footer_color;
    background-color: $footer_background_color;
}
.footer_p .mention a, .footer_p a{
    color: $footer_color;
}

/* blog */

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    background-color: $background_blog_menu;
    border-color: $background_blog_menu;
}
.pagination > li > a, .pagination > li > span{
    color:$background_blog_menu;
}
.row.two-column-blog .apercu-article h2.titre-article, .single-article .titre-article h1, .apercu-article h2.titre-article-no-image,
.row.two-column-blog .apercu-article .h2.titre-article, .single-article .titre-article .h1, .apercu-article .h2.titre-article-no-image{
    background:$background_blog_h2;
}
.pagination.pull-right li.active a{
    color:$white;
}

.template_album .list-photos .galerie .addcommentaires{
    border-color:$body_texte_color;
    color:$body_texte_color;
}