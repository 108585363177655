 
/* Général */
$body_background_color : #fff;
$body_texte_color: #222; 
$content_background_color: #fff;
$link_color : #222;
$link_hover_color : #222;
$button_background_color : #222;
$button_border_color : #222;
$button_color : #fff;
$button_hover_background_color : #fff;
$button_hover_border_color : #222;
$button_hover_color : #222;
$h2_color : #fff;
$h2_background_color : #222;

/* Header */
$header_background_color : #fff;
$slogan_color : #222;
$menu_background_color : #222;
$nav_background_color : #222;
$nav_color : #fff;
$nav_actif_background_color : #afafaf;
$nav_actif_color : #fff;
$menu_burger_color : #afafaf;
$menu_burger_hover_background_color : #afafaf;
$menu_burger_hover_color : #fff;

/* Content */
$sidebar_rubrique_color : #222;
$sidebar_rubrique_border_color : #222;
$fil_darianne_actif_color : #afafaf;
$form_perso_background_color : #fafafa;

/* accueil */
$prod_accueil_background_color : rgba(34, 34, 34, .6);
$prod_accueil_color : #fff;
$slider_titre_background_color : rgba(34, 34, 34, .6);

/* boutique */
$produit_color : #222;
$etiquette_background_color : #222;
$etiquette_color : #fff;
$remise_color : #222;
$zoom_background_color : #222;
$prix_color : #222;
$old_prix_color : #b1b2b6;

$ficheprod_remise_background_color : #afafaf;

/* livre d'or */
$msglo_h2_background_color : #555;
$msglo_h2_color : #fff;
$msglo_note_background_color : #afafaf; 
$msglo_note_color : #fff;

/* contact */
$form_focus_shadow: 0 0 5px #222;
$form_focus_bordercolor: #222;


/* Footer */
$footer_color : #fff;
$footer_background_color : #292628;

/* Blog */
$background_blog_menu :  #222 !important;
$background_blog_h2 : #222;
$white : #fff !important;

/* Template Panier */
$panier-background-color:#fff;
$panier-titre-color:#000;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

/* Flux panier */
$flux-panier_color-shadow:black;

@import "design/745series/declinaison";

